import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "render-props",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#render-props",
        "aria-label": "render props permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Render Props`}</h1>
    <blockquote>
      <p parentName="blockquote">{`This is a legacy API for compatibility with v1.x users moving to Popper v2. We
recommend using the `}<a parentName="p" {...{
          "href": "../hook/"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`usePopper`}</code>{` Hook`}</a>{` in new code.`}</p>
    </blockquote>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Manager`}</code>{` component is a simple wrapper that needs to surround all the other
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`react-popper`}</code>{` components in order to make them communicate with each others.`}</p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Popper`}</code>{` component accepts the properties `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`children`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`placement`}</code>{`,
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`modifiers`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`strategy`}</code>{`.`}</p>
    <x-ad />
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Popper`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`innerRef`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`node`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`popperNode `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` node`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`placement`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`right`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`modifiers`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` name`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'preventOverflow'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` enabled`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token boolean"
              }}>{`false`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`strategy`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`fixed`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Popper`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "children",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#children",
        "aria-label": "children permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`children`}</code></h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`ref`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`?`}</span>{`HTMLElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  style`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`string`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` string `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` number `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  placement`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`Placement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  isReferenceHidden`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`boolean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  hasPopperEscaped`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`boolean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`update`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`forceUpdate`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  arrowProps`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`ref`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`?`}</span>{`HTMLElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    style`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`string`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` string `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` number `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Node`}</code></pre></div>
    <p>{`A function (render prop) that takes as argument an object containing the
following properties:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><code parentName="strong" {...{
            "className": "language-text"
          }}>{`ref`}</code></strong>{`: used to retrieve the
`}<a parentName="li" {...{
          "href": "https://reactjs.org/docs/refs-and-the-dom.html"
        }}>{`React refs`}</a>{` of the `}<strong parentName="li">{`popper`}</strong>{`
element.`}</li>
      <li parentName="ul"><strong parentName="li"><code parentName="strong" {...{
            "className": "language-text"
          }}>{`style`}</code></strong>{`: contains the necessary CSS styles (React CSS properties) which
are computed by Popper.js to correctly position the `}<strong parentName="li">{`popper`}</strong>{` element.`}</li>
      <li parentName="ul"><strong parentName="li"><code parentName="strong" {...{
            "className": "language-text"
          }}>{`placement`}</code></strong>{`: describes the placement of your popper after Popper.js has
applied all the modifiers that may have flipped or altered the originally
provided `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`placement`}</code>{` property. You can use this to alter the style of the
popper and or of the arrow according to the definitive placement. For
instance, you can use this property to orient the arrow to the right
direction.`}</li>
      <li parentName="ul"><strong parentName="li"><code parentName="strong" {...{
            "className": "language-text"
          }}>{`isReferenceHidden`}</code></strong>{`: a boolean signifying the reference element is fully
clipped and hidden from view.`}</li>
      <li parentName="ul"><strong parentName="li"><code parentName="strong" {...{
            "className": "language-text"
          }}>{`hasPopperEscaped`}</code></strong>{`: a boolean signifying the popper escapes the reference
element's boundary (and so it appears detached).`}</li>
      <li parentName="ul"><strong parentName="li"><code parentName="strong" {...{
            "className": "language-text"
          }}>{`update`}</code></strong>{`: a function you can ask Popper to recompute your tooltip's
position . It will directly call the
`}<a parentName="li" {...{
          "href": "../../../docs/v2/lifecycle/#manual-update"
        }}>{`Popper#update`}</a>{` method.`}</li>
      <li parentName="ul"><strong parentName="li"><code parentName="strong" {...{
            "className": "language-text"
          }}>{`arrowProps`}</code></strong>{`: an object, containing `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`style`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ref`}</code>{` properties that are
identical to the ones provided as the first and second arguments of
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`children`}</code>{`, but relative to the `}<strong parentName="li">{`arrow`}</strong>{` element. The `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`style`}</code>{` property
contains `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`left`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`top`}</code>{` offset values, which are used to center the arrow
within the popper. These values can be merged with further custom styling and
positioning. See
`}<a parentName="li" {...{
          "href": "https://github.com/FezVrasta/react-popper/blob/8994933c430e48ab62e71495be71e4f440b48a5a/demo/styles.js#L100"
        }}>{`the demo`}</a>{`
for an example.`}</li>
    </ul>
    <h3 {...{
      "id": "innerref",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#innerref",
        "aria-label": "innerref permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`innerRef`}</code></h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`innerRef`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`?`}</span>{`HTMLElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span></code></pre></div>
    <p>{`Function that can be used to obtain popper reference`}</p>
    <h3 {...{
      "id": "placement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#placement",
        "aria-label": "placement permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`placement`}</code></h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`placement`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PopperJS$Placement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`One of the accepted placement values listed in the
`}<a parentName="p" {...{
        "href": "../../../docs/v2/constructors/#options"
      }}>{`Popper.js documentation`}</a>{`.`}<br parentName="p"></br>{`
`}{`Your popper is going to be placed according to the value of this property.`}<br parentName="p"></br>{`
`}{`Defaults to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bottom`}</code>{`.`}</p>
    <h3 {...{
      "id": "strategy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#strategy",
        "aria-label": "strategy permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`strategy`}</code></h3>
    <p>{`Describes the positioning strategy to use. By default, it is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`absolute`}</code>{`, which
in the simplest cases does not require repositioning of the popper. If your
reference element is in a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fixed`}</code>{` container, use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fixed`}</code>{` strategy.
`}<a parentName="p" {...{
        "href": "../../../docs/v2/constructors/#strategy"
      }}>{`Read More`}</a></p>
    <h3 {...{
      "id": "modifiers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiers",
        "aria-label": "modifiers permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`modifiers`}</code></h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PopperJS$Modifiers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`An object containing custom settings for the
`}<a parentName="p" {...{
        "href": "../../../docs/v2/modifiers/"
      }}>{`Popper.js modifiers`}</a>{`.`}<br parentName="p"></br>{`
`}{`You can use this property to override their settings or to inject your custom
ones.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      